/**
 * @flow
 */

import React, { type Node } from "react";

import { AlertConfirmation } from "./AlertConfirmation";
import { Button } from "./Button";
import type { AlertConfirmationProps, AlertConfirmationButtonsBuilderType } from "./AlertConfirmation";

/* eslint-disable react/jsx-props-no-spreading */

type AlertConfirmationPropsWithoutRenderButtons = $Diff<
  AlertConfirmationProps,
  { buttonsBuilder: AlertConfirmationButtonsBuilderType },
>;

type Props = {|
  ...$Exact<AlertConfirmationPropsWithoutRenderButtons>,
  warningButtonLabel: string,
  warningButtonAction: (event?: Event, data: any) => void,
  warningButtonDisabled?: boolean,
  warningButtonData?: any,
  againstConventionsCancelButtonLabel?: string,
  cancelButtonAction?: () => void,
|};

export const WarningAlertConfirmation = (props: Props): Node => (
  <AlertConfirmation
    {...props}
    icon={{ style: "solid", name: "exclamation-circle" }}
    // eslint-disable-next-line i18next/no-literal-string
    iconColor="orange"
    buttonsBuilder={(hideAlertFn) => [
      <Button
        key="warningButton"
        type="primary"
        title={props.warningButtonLabel}
        onPress={(event) => {
          props.warningButtonAction(event, props.warningButtonData);
          hideAlertFn();
        }}
        disabled={props.warningButtonDisabled}
      />,
      <Button
        key="cancelButton"
        title={
          props.againstConventionsCancelButtonLabel ||
          window.pgettext("The button to do nothing when an alert is shown.", "Not Now")
        }
        onPress={() => {
          hideAlertFn();
          if (props.cancelButtonAction) {
            props.cancelButtonAction();
          }
        }}
      />,
    ]}
  />
);
